import React, { useEffect, useState } from 'react'

import '@livekit/components-styles';
import {
  LiveKitRoom,
  VideoConference
} from '@livekit/components-react';

import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiManager } from '../api/API_Manager';
import Modal from 'react-bootstrap/Modal';
import { Button, ListGroup, ModalBody, Spinner } from 'react-bootstrap';
import { serverUrl } from '../util/constants';
import { Icon } from '@iconify/react';

export default function Meet() {

  const accessToken = localStorage.getItem('accessToken')
  const organizer = localStorage.getItem('role') === 'organizer'
  
  const [pendingRequests, setPendingRequests] = useState([]);
  const [pendingRequestsCount, setPendingRequestsCount] = useState(0);
  const [show, setShow] = useState(false)
  const [interval, setinterval] = useState(null)
  
  // check livekit token
  const [livekitToken, setLivekitToken] = useState('')
  const [roomData, setRoomData] = useState(null)
  const [recordingStarted, setRecordingStarted] = useState(false)
  const [modalName, setModalName] = useState('')
  useEffect(() => {

    const askPermission = () => {
      const permissions = navigator.mediaDevices.getUserMedia({ audio: true, noiseSuppression: true})
      permissions.then(() => {
          // setMicButton({ ...micButton, off: false })
      })
      .catch((err) => {
          console.error(err)
      });
    }
    askPermission()

    let roomToken = localStorage.getItem('liveKitToken') || ''
    console.log(roomToken)
    if (roomToken !== undefined && roomToken !== null) {
      setLivekitToken(roomToken)
    }

    // Get room data
    const recordRoomData = JSON.parse(localStorage.getItem('record_room_data'))
    if (recordRoomData !== undefined && recordRoomData !== null) {
      setRoomData(recordRoomData)
    }
    
  }, [])

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    console.log(pendingRequests)
    setShow(true)
  }

  const getPendingRequests = () => {
    apiManager('GET', 'pendingRequests', null, { accesstoken: accessToken })
      .then(res => {
        if (res.status) {
          if (res.data.count > pendingRequestsCount) setPendingRequestsCount(res.data.count)
          setPendingRequests(res.data.rows)
          setButtonSpinner(false)
        } else {
          setButtonSpinner(false);
          setPendingRequests([])
          setPendingRequestsCount(0)
          console.info('Could not get pending requests.')
        }
      })
      .catch(err => {
        toast.error('Something went wrong!')
        console.error(err)
      })
  }

  const [selectedRequest, setSelectedRequest] = useState({})
  const [buttonSpinner, setButtonSpinner] = useState(false)

  const handleApproveReject = (permission, id) => {
    setSelectedRequest({
      perm: permission,
      id: id
    })
    setButtonSpinner(true)

    const apiData = {
      requestId: id,
      status: permission
    }

    apiManager("PUT", 'action', apiData, { accesstoken: accessToken })
      .then(res => {

        if (res.status) {
          getPendingRequests()
          // setButtonSpinner(false)
        } else {
          toast.error('Cannot Approve or Reject.')
          setButtonSpinner(false)
        }

      })
      .catch(err => {
        console.error(err)
        toast.error('Something went wrong!')
        setButtonSpinner(false)
      })
  }

  const navigate = useNavigate();
  const location = useLocation();

  const onConnected = () => {
    toast.info('Welcome!')
    const role = localStorage.getItem('role')
    if (role === 'organizer') {
      getPendingRequests()
      const connect = setInterval(function () {
        getPendingRequests()
      }, 5000);
      setinterval(connect)
    }

  }

  // Call Record Meeting API when organizer joined the meeting
  const [allowVideoRecord, setAllowVideoRecord] = useState(false)
  const [show1, setShow1] = useState(false)
  const [btnSubmitClicked, setBtnSubmitClicked] = useState(false)

  const handleClose1 = () => {
    setShow1(false)
  }

  const recordRoom = () => {
    if (roomData) {
      if (!recordingStarted) {
        setRecordingStarted(true)
        setBtnSubmitClicked(true)
        apiManager('POST', 'meetRecord', {...roomData, recordVideo: allowVideoRecord})
        .then(res => {
          if (res.status) {
              toast.success('Recording started !!')
              handleClose1()
              setBtnSubmitClicked(false)
              console.log(res)
            } else {
              // navigate('/');
              setBtnSubmitClicked(false)
              toast.error(res.message || 'Something went wrong');
            }
        })
        .catch(err => {
          console.error(err)
          setBtnSubmitClicked(false)
          toast.error('Something went wrong!')
        })
      } else {
        setRecordingStarted(true)
        setBtnSubmitClicked(true)
        apiManager('POST', 'stopRecording', roomData)
        .then(res => {
          if (res.status) {
            toast.success('Recording ended !!')
            handleClose1()
            setBtnSubmitClicked(false)
            setRecordingStarted(false)
            console.log(res)
          } else {
            // navigate('/');
            setBtnSubmitClicked(false)
            toast.error(res.message || 'Something went wrong');
          }
        }).catch(err => {
          console.error(err)
          setBtnSubmitClicked(false)
          toast.error('Something went wrong!')
        })
        
      }
    }
  }

  const onDisconnected = async () => {
    clearInterval(interval)
    localStorage.setItem('accessToken', '')
    localStorage.setItem('liveKitToken', '')
    toast.info('You Left The Meeting')
    navigate('/')
  }

  // play audio when someone request to join meeting
  // const audio = new Audio('/audio/Notification.mp3', { volume: 0.1 });
  // useEffect(() => {
  //   if (pendingRequestsCount > 0) {
  //     audio.play();
  //   }
  // }, [pendingRequestsCount])

  // useEffect(() => {
  //   // This function will be triggered when shouldPlaySound changes
  //   if (pendingRequestsCount > 0) {
  //     playJoinMeetingSound();
  //   }
  // }, [pendingRequestsCount]);

  // // Function to play the join meeting sound
  // function playJoinMeetingSound() {
  //   const audio = new Audio('/audio/Notification.mp3');
  //   audio.play();
  // }

  const [audio] = useState(new Audio('/audio/Notification.mp3'));
  useEffect(() => {
    // This function will be triggered when shouldPlaySound changes
    console.log('pendingRequestsCount', pendingRequestsCount);
    if (pendingRequestsCount > 0) {
      audio.play();
    }
  }, [pendingRequestsCount]);

  // Share Meeting Link

  const shareMeetingLink = async () => {
    const meetingLink = `https://meetnext.deepbluework.com?room_id=${roomData?.room_id}`
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(meetingLink);
      toast.success('Link Copied!!')
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <LiveKitRoom
        video={location.state.camToggle}
        audio={location.state.micToggle}
        token={livekitToken}
        connectOptions={{ autoSubscribe: false }}
        serverUrl={serverUrl}
        options={{disconnectOnPageLeave: true}}
        onEnded={() => console.log("test ended")}
        // Use the default LiveKit theme for nice styles.
        data-lk-theme="default"
        style={{ height: '100vh' }}
        onConnected={room => onConnected(room)}
        onDisconnected={(room) => onDisconnected(room)}
        onError={(error) => console.log("error livekit:---------", error)}
      >
        <VideoConference />
      </LiveKitRoom>


      <Modal size='md' show={show} onHide={handleClose}>
        <Modal.Body className='bg-dark'>
          <Modal.Title className='mb-4' style={{ color: 'darkgrey' }}>
            Pending Requests
          </Modal.Title>
          {pendingRequests.length ? 
          <ListGroup>
            {pendingRequests.map((item, index) => {
              return (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center my-2 rounded-3"
                  style={{'background': 'rgb(24 23 28)',
                    'boxShadow': '1px 1px 10px 0px black',
                    'color': 'white',
                    'border': '0px'}}
                  >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{item.fullName}</div>
                  </div>
                  <div>
                    <span className='mx-2' style={{background: '#009800', border: 'none', display: 'inline-block', borderRadius: '25px', padding: '4px', cursor: 'pointer'}} disabled={buttonSpinner} onClick={() => handleApproveReject('Approved', item.id, index)}>{(selectedRequest.id === item.id && selectedRequest.perm === 'Approved' && buttonSpinner) ? <Spinner size='sm' animation="border" /> : <Icon icon={'uis:check'} fontSize={25} color='white'/>}</span>
                    <span className='mx-1' style={{background: 'rgb(237 40 56)', border: 'none', display: 'inline-block', borderRadius: '25px', padding: '4px', cursor: 'pointer'}} disabled={buttonSpinner} onClick={() => handleApproveReject('Rejected', item.id, index)} size='sm'>{(selectedRequest.id === item.id && selectedRequest.perm === 'Rejected' && buttonSpinner) ? <Spinner size='sm' animation="border" /> : <Icon icon={'lucide:x'} fontSize={25} color='white'/>}</span>
                  </div>
                </ListGroup.Item>
              )
            })}
          </ListGroup> : <div className='mt-5'>
              <p className='text-center' style={{color: '#ebebeb'}}>No Pending Requests Found.</p>
            </div>
          }
          {/* <Modal.Footer> */}
            <Button className='btn btn-danger float-end mt-3' onClick={handleClose}>Close</Button>
          {/* </Modal.Footer> */}
        </Modal.Body>
      </Modal>

      {organizer && <>
        <div className='pr_container' style={{ position: 'absolute', bottom: '15px', left: '10px', height: '45px', width: 'fit-content'}}>
          <button type="button" className="h-100 btn position-relative" style={{width: '50px', 
          background: 'rgb(55 53 53)',
          marginRight: '10px',
          borderRadius: '65px',
          boxShadow: '1px 1px 7px 3px #242222'
        }} onClick={handleShow}>
            <Icon icon={'fa-solid:users'} fontSize={20} color='white'/>
            {pendingRequestsCount > 0 && <>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {pendingRequestsCount}
                <span className="visually-hidden">unread messages</span>
              </span>
            </>}
          </button>

          {recordingStarted ? <button type="button" className="h-100 btn position-relative" style={{width: '50px',
          background: 'rgb(55 53 53)',
          marginRight: '10px',
          cursor: 'pointer',
          borderRadius: '65px',
          boxShadow: '1px 1px 7px 3px #242222'
        }} onClick={() => {setShow1(true); setModalName('Stop Recording')}}>
            <Icon icon={'solar:videocamera-record-bold'} fontSize={20} color={'#fa3343'}/>
          </button> : <button type="button" className="h-100 btn position-relative" style={{width: '50px',
          background: 'rgb(55 53 53)',
          marginRight: '10px',
          cursor: 'pointer',
          borderRadius: '65px',
          boxShadow: '1px 1px 7px 3px #242222'
        }} onClick={() => {setShow1(true); setModalName('Start Recording')}}>
            <Icon icon={'solar:videocamera-record-bold'} fontSize={20} color={recordingStarted ? '#fa3343' : '#ffffff'}/>
          </button>
          }
        
          <button type="button" className="h-100 btn position-relative" style={{width: '50px',
          background: 'rgb(55 53 53)',
          borderRadius: '65px',
          cursor: 'pointer',
          boxShadow: '1px 1px 7px 3px #242222'
        }} onClick={() => shareMeetingLink()}>
            <Icon icon={'fa-solid:share'} fontSize={20} color='white'/>
          </button>
        </div>

        <Modal size='md' show={show1} onHide={handleClose1}>
        <Modal.Body className='bg-dark'>
          <Modal.Title className='mb-4' style={{ color: 'darkgrey' }}>
            {modalName === "Start Recording" ? "Start Recording With" : "Stop Recording"} 
          </Modal.Title>
          {modalName === "Stop Recording" ? <>
          <p className='text-white'>Are you sure want to stop recording?</p>
            <Button className='btn btn-primary float-end mt-3 mx-2' disabled={btnSubmitClicked} onClick={() => recordRoom()}>Submit</Button>
            <Button className='btn btn-danger float-end mt-3' onClick={handleClose1}>Close</Button>
          </> : <>
          <div className='d-flex justify-content-start align-items-center'>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="radio1" name="optradio" value="option1" checked disabled></input>
              <label class="form-check-label text-white" for="radio1">Audio</label>
            </div>
            <div class="form-check mx-4">
              <input type="checkbox" class="form-check-input" id="radio2" name="optradio" value="option2" onChange={(event) => setAllowVideoRecord(event.target.checked)}></input>
              <label class="form-check-label text-white" for="radio2">Video</label>
            </div>
          </div>
            <Button className='btn btn-primary float-end mt-3 mx-2' disabled={btnSubmitClicked} onClick={() => recordRoom()}>Submit</Button>
            <Button className='btn btn-danger float-end mt-3' onClick={handleClose1}>Close</Button>
          </>
          }
        </Modal.Body>
      </Modal>


        {/* <div className='pr_container' style={{ position: 'absolute', bottom: '15px', left: '10px', height: '45px', width: 'fit-content'}}>
        </div> */}
      </>}
    </div>
  );
}