import { BASE_URL } from "../util/constants";
import { AUTH } from "../util/constants";

export const apiManager = async (method, path, data, addHeaders) => {
    let config = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: AUTH
        },
        body: JSON.stringify(data)
    }

    if (method === 'GET') {
        delete config.body;
    }
    if (addHeaders) {
        config = { ...config, headers: { ...config.headers, ...addHeaders } };
    }

    return fetch(`${BASE_URL}${path}`, config)
        .then(res => {
            return res.json()
        })
        .catch(error => {
            return error
        })

}