// export const BASE_URL = 'https://meet-api.cybertrons.io/api_v1/'
// export const AUTH = 'f8ca6e56f732b98192c57d964f952e3d59b0ec9f936594f524d4b73e263c8a38ebc3f1e6a1f824f42c00a3ff8409916e33a006209b0ec9f936594f524d4b73e2'

// export const serverUrl = 'wss://livekit.cybertrons.io';

// export const waitingTime = 3 // in minutes 

export const BASE_URL = 'https://meetapi.deepbluework.com/api_v1/'
export const AUTH = 'f8ca6e56f732b98192c57d964f952e3d59b0ec9f936594f524d4b73e263c8a38ebc3f1e6a1f824f42c00a3ff8409916e33a006209b0ec9f936594f524d4b73e2'
export const serverUrl = 'wss://livekit.deepbluework.com';
export const waitingTime = 3 // in minutes 