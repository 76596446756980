import React from 'react'

export default function Header() {
    return (
        <div>
            <nav className="navbar bg-body-tertiary border rounded">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={'/favicon.png'} alt="Bootstrap" height="50" />
                    </a>
                </div>
            </nav>
        </div>
    )
}
