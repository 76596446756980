import './App.css';
import '@livekit/components-styles';
import JoinMeeting from './components/JoinMeeting';
import Meet from './components/Meet';

import {
  Routes,
  Route,
} from "react-router-dom";

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<JoinMeeting />}></Route>
      <Route path='/meet' element={<Meet />}></Route>
    </Routes>
  );
}