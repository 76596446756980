import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import Webcam from 'react-webcam';
import Layout from './Layout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CircleLoader, RiseLoader } from 'react-spinners';
import { apiManager } from '../api/API_Manager';
import { waitingTime } from '../util/constants';
import { Spinner } from 'react-bootstrap';

export default function JoinMeeting() {

    
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate();

    const askCameraPermission = () => {
        const permissions = navigator.mediaDevices.getUserMedia({ video: true })

        permissions.then(() => {
            setCamButton({ ...camButton, off: false })
        }).catch((err) => {
            setCamButton({ ...camButton, altText: 'Permission denied' })
            console.error(err)
        });
    }

    const askMicPermission = () => {
        const permissions = navigator.mediaDevices.getUserMedia({ audio: true })

        permissions.then(() => {
            setMicButton({ ...micButton, off: false })
        })
        .catch((err) => {
            console.error(err)
        });
    }

    const [params] = useSearchParams();
    const organizer = params.get('organizer') === 'true'
    const roomInURL = params.get('room_id') !== null
    const isError = params.get('error') === 'Forbidden'
    const [interval, setinterval] = useState(null)
    


    const [micButton, setMicButton] = useState({ color: 'white', off: true })
    const [camButton, setCamButton] = useState({ color: 'white', off: true, altText: 'Camera is off' })


    const joinSchema = yup.object().shape({
        name: yup.string().required().label('Name'),
        password: yup.string().required().label('Password'),
        room_id: yup.string().required().label('Room ID')
    })

    const {
        control: joinControls,
        setValue: setJoinValues,
        handleSubmit: handleJoinMeeting,
        setError: setJoinError,
        formState: { errors: joinErrors }
    } = useForm({
        defaultValues: {
            password: organizer ? '' : 'Abcd@123',
            name: '',
            room_id: roomInURL ? params.get('room_id') : ''
        },
        mode: 'onBlur',
        resolver: yupResolver(joinSchema)
    })

    const clearForm = () => {
        setJoinValues('password', '')
        setJoinValues('name', '')
        setJoinValues('room_id', '')

        setJoinError('password', '')
        setJoinError('name', '')
        setJoinError('room_id', '')
    }

    const handleMicToggleClick = () => {
        if (micButton.off) {
            askMicPermission();
            setMicButton({ ...micButton, off: !micButton.off })
        } else {
            setMicButton({ ...micButton, off: !micButton.off })
        }
    }
    const handleCamToggleClick = () => {
        if (camButton.off) {
            askCameraPermission()
            setCamButton({ ...camButton, off: !camButton.off })
        } else {
            setCamButton({ ...camButton, altText: 'Camera is off', off: !camButton.off })
        }
    }

    const generateCreatorToken = (data) => {
        apiManager('POST', 'generateCreatorToken', data)
            .then(res => {
                if (res.status) {
                    console.log(res)
                    localStorage.setItem('liveKitToken', res.data.liveKitToken);
                    localStorage.setItem('accessToken', res.data.accessToken);
                    localStorage.setItem('role', res.data.role);
                    localStorage.setItem('room_id', res.data.room);
                    localStorage.setItem('record_room_data', JSON.stringify(data));
                    navigate('/meet', { state: { camToggle: !camButton.off, micToggle: !micButton.off} });
                } else {
                    clearForm();
                    setFullPageLoading(false)
                    navigate('/');
                    toast.error('Could not enter the room.');
                }
            })
            .catch(err => {
                console.error(err)
                setFullPageLoading(false)
                navigate('/')
                toast.error('Something went wrong!')
            })
    }

    const letMeIn = (data, connect) => {
        apiManager('POST', 'letMeIn', data, { accesstoken: localStorage.getItem('accessToken') })
            .then(res => {
                console.log('hello connect',connect)
                if (res.status) {
                    localStorage.setItem('liveKitToken', res.data.liveKitToken)
                    localStorage.setItem('accessToken', res.data.accessToken)
                    localStorage.setItem('requestId', res.data.requestId)
                    localStorage.setItem('identity', res.data.identity)
                    localStorage.setItem('role', res.data.role)
                    clearInterval(connect)

                    navigate('/meet', { state: { camToggle: !camButton.off, micToggle: !micButton.off } });
                    return true
                } else {
                    if (res.message === 'Pending') {
                        return false
                    } else {
                        clearInterval(connect)
                        navigate('/')
                        toast.warn('Your request was rejected by the meeting organizer.')
                        setLoading(false)
                    }
                }
            })
            .catch(err => {
                console.error(err)
                clearInterval(connect)
                navigate('/')
                toast.error('Something went wrong!')
                return false
            })
    }

    // Request Loading
    const [btnClickedToRequest, setBtnClickedToRequest] = useState(false)
    const handleJoin = (data) => {
        console.log(data)
        setBtnClickedToRequest(true)
        if (organizer) {
            const apiData = {
                password: data.password,
                room_id: data.room_id,
                fullName: data.name
            }
            apiManager('POST', 'generateOrganizerToken', apiData).then(res => {
                if (res.status) {
                    localStorage.setItem('liveKitToken', res.data.liveKitToken)
                    localStorage.setItem('accessToken', res.data.accessToken)
                    localStorage.setItem('room_id', res.data.room)
                    navigate('/meet', { state: { camToggle: !camButton.off, micToggle: !micButton.off } });
                } else {
                    toast.error('Could not get in.');
                }
                setBtnClickedToRequest(false)
            }).catch(err => {
                console.error(err)
                setBtnClickedToRequest(false)
                toast.error('Something went wrong!')
            })
        } else {
            const apiData = {
                room_id: data.room_id,
                fullName: data.name
            }
            apiManager("POST", 'joinRequest', apiData).then(res => {
                if (res.status) {
                    setLoading(true)
                    toast.success(res.message)
                    localStorage.setItem('accessToken', res.data.accessToken)
                    localStorage.setItem('room_id', res.data.room)
                    requestJoin({ requestId: res.data.requestId }, letMeIn)
                    setBtnClickedToRequest(false)
                } else {
                    toast.error(res.message);
                    setBtnClickedToRequest(false)
                }
            }).catch(err => {
                console.error(err)
                setBtnClickedToRequest(false)
                toast.error('Something went wrong!')
            })
        }
    }


    const [loading, setLoading] = useState(false)
    const requestJoin = (data, func) => {
        setLoading(true)
        let accepted = func(data);
        let attempts = 1;

        const connect = setInterval(function () {
            if (accepted || attempts === (waitingTime * 12)) {
                clearInterval(connect)
                if (!accepted) {
                    clearForm();
                    navigate('/')
                    toast.error('Your request to join has expired, try again.')
                }
                setLoading(false)
            } else {
                console.log('connecting')
                accepted = func(data, connect);
                if (accepted) setLoading(false);
                attempts++;
            }
            console.log('hello')
        }, 5000)

        setinterval(connect)
    }

    const [fullPageLoading, setFullPageLoading] = useState(false)

    useEffect(() => {
        const room_id = params.get('room_id')
        const actionToken = params.get('actionToken')

        console.log('called')
        console.log('error called: ', isError)
        if (isError) {
            toast.error('Forbidden, Please contact support if you think this was a mistake.')
            navigate('/')
        } else {
            if (room_id && actionToken) {
                setFullPageLoading(true)
                generateCreatorToken({ room_id: room_id, actionToken: actionToken })
            }
        }

    }, [])

    if (fullPageLoading) {
        return (
            <div className="container">
                <div className='row'>
                    <div className="col-lg-12 d-flex justify-content-around" style={{ marginTop: '150px' }}>
                        <RiseLoader loading={fullPageLoading} size={30} color="blue" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Layout>
            <div className='' style={{ height: 'calc(100% - 200px)' }}>
                <div className="container h-100 d-flex justify-content-center">
                    <div className="row" style={{width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>

                        <div className="mt-5 col-lg-6 d-flex justify-content-around ">
                            <div className='camPreview w-100'>
                                {!camButton.off ? <>
                                    <Webcam
                                        audio={!micButton.off}
                                        mirrored
                                    />
                                </> : <>
                                    <div className="row text-center">
                                        <span className='camOffText fw-bold fs-4'>{camButton.altText}</span>
                                    </div>
                                </>}
                                <div className='d-flex w-100 justify-content-evenly align-items-center toggleButtons'>
                                    <div>
                                        {console.log("mic off: ", micButton.off)}
                                        <Icon className='mx-2 toggleButton' style={{cursor: 'pointer', background: !micButton.off ? 'transparent' : '#fa3343', border: !micButton.off ? '1px solid white' : 'none'}} onClick={handleMicToggleClick} onMouseEnter={() => setMicButton({ ...micButton, color: 'white' })} onMouseLeave={() => setMicButton({ ...micButton, color: 'white' })} icon={micButton.off ? 'material-symbols:mic-off' : 'material-symbols:mic'} color={micButton.color} width="40" />
                                        <Icon className='mx-2 toggleButton' style={{cursor: 'pointer', background: !camButton.off ? 'transparent' : '#fa3343', border: !camButton.off ? '1px solid white' : 'none'}} onClick={handleCamToggleClick} onMouseEnter={() => setCamButton({ ...camButton, color: 'white' })} onMouseLeave={() => setCamButton({ ...camButton, color: 'white' })} icon={camButton.off ? 'mdi:camera-off' : 'mdi:camera'} color={camButton.color} width="40" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="mt-5 col-lg-1"></div> */}

                        <div className="mt-5 entries col-lg-5">
                            {loading ? <>
                                <div className='row' style={{
                                    'height': '100%',
                                    'display': 'flex',
                                    'justifyContent': 'space-evenly',
                                    'alignContent': 'center'
                                }}
                                >
                                    <div className="col-lg-12 d-flex justify-content-around" style={{
                                        marginBottom: '70px'
                                    }}>
                                        <RiseLoader loading={loading} size={15} color="blue" />
                                    </div>
                                    <div className="col-lg-12 d-flex justify-content-around" style={{ }}>
                                        <h5 className='fs-4 text-center'>Waiting for meeting organizer to let you in.</h5>
                                    </div>
                                </div>

                            </> : <>

                                <br />
                                <h5 className='display-5 mb-5 text-center' style={{fontWeight: '400'}}>Ready to join..?</h5>
                                <div>
                                    {!roomInURL && <>
                                        <div className="mb-3">
                                            <label className="form-label">Room ID</label>
                                            <Controller
                                                name='room_id'
                                                control={joinControls}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <input placeholder='Room ID' type="email" value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                )}
                                            />
                                            {joinErrors.room_id && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.room_id.message}</span>}
                                        </div>
                                    </>}

                                    <div className="mb-3">
                                        <label className="form-label">Display name</label>
                                        <Controller
                                            name='name'
                                            control={joinControls}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => (
                                                <input placeholder='John Doe' type="email" value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                            )}
                                        />
                                        {joinErrors.name && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.name.message}</span>}
                                    </div>

                                    {organizer ? <>
                                        <div className="mb-3">
                                            <label className="form-label">Organizer Password</label>
                                            <Controller
                                                name='password'
                                                control={joinControls}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <div style={{position: 'relative'}}>
                                                        <input placeholder='Password' type={showPassword ? "text" : "password"} value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                        <span style={{position: 'absolute', top: '10px', right: '10px', cursor: 'pointer'}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Icon icon={"mdi:eye"} fontSize={20}/> : <Icon icon={"basil:eye-closed-solid"} fontSize={20}/>}</span>
                                                    </div>
                                                )}
                                            />
                                            {joinErrors.password && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.password.message}</span>}
                                        </div>
                                    </> : <>

                                        {/* <div className="mb-3">
                                            <label className="form-label">Email address (optional)</label>
                                            <Controller
                                                name='email'
                                                control={joinControls}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <input placeholder='abc@gmail.com' type="email" value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                )}
                                            />
                                            {joinErrors.email && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.email.message}</span>}
                                        </div> */}
                                    </>}
                                    <div className="d-flex justify-content-around mt-5">
                                        <button className="btn btn-primary rounded-5" style={{backgroundColor: '#235aff', paddingTop: btnClickedToRequest ? '8px' : undefined, minWidth: '150px', fontSize: 20, fontWeight: 500}} disabled={btnClickedToRequest} onClick={handleJoinMeeting(handleJoin)}>{btnClickedToRequest ? <Spinner /> : 'Request'}</button>
                                    </div>
                                </div>
                            </>}
                        </div>

                    </div>
                </div>
            </div>
        </Layout >
    )
}


{/* <div className="container-fluid bg-primary">
    <div className="row h-100">

        <div className="col-lg-6 rounded-3">
            <div className='camPreview'>
                {!camButton.off ? <>
                    <Webcam
                        audio={!micButton.off}
                        // videoConstraints={{ deviceId:    'default_cam' }}
                        mirrored
                    />
                </> : <>
                    <div className="row text-center">
                        <span className='camOffText fw-bold fs-4'>{camButton.altText}</span>
                    </div>
                </>}
                <div className='d-flex w-100 justify-content-evenly align-items-center toggleButtons'>
                    <div>
                        <Icon className='mx-2 toggleButton' onClick={handleMicToggleClick} onMouseEnter={() => setMicButton({ ...micButton, color: 'red' })} onMouseLeave={() => setMicButton({ ...micButton, color: 'white' })} icon={micButton.off ? 'material-symbols:mic-off' : 'material-symbols:mic'} color={micButton.color} width="40" />
                        <Icon className='mx-2 toggleButton' onClick={handleCamToggleClick} onMouseEnter={() => setCamButton({ ...camButton, color: 'red' })} onMouseLeave={() => setCamButton({ ...camButton, color: 'white' })} icon={camButton.off ? 'mdi:camera-off' : 'mdi:camera'} color={camButton.color} width="40" />
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-1"></div>

        <div className="col-lg-5">
            {loading ? <>
                <div className='row'>
                    <div className="col-lg-12 d-flex justify-content-around" style={{ marginTop: '150px' }}>
                        <RiseLoader loading={loading} size={15} color="blue" />
                    </div>
                    <div className="col-lg-12 d-flex justify-content-around" style={{ marginTop: '150px' }}>
                        <h5 className='fs-4'>Waiting for meeting organizer to let you in.</h5>
                    </div>
                </div>

            </> : <>

                <h5 className='display-5 mb-5'>Ready to join..?</h5>
                <div>
                    {!roomInURL && <>
                        <div className="mb-3">
                            <label className="form-label">Room ID</label>
                            <Controller
                                name='room_id'
                                control={joinControls}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <input type="email" value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                )}
                            />
                            {joinErrors.room_id && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.room_id.message}</span>}
                        </div>
                    </>}

                    <div className="mb-3">
                        <label className="form-label">Display name</label>
                        <Controller
                            name='name'
                            control={joinControls}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <input type="email" value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            )}
                        />
                        {joinErrors.name && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.name.message}</span>}
                    </div>

                    {organizer ? <>
                        <div className="mb-3">
                            <label className="form-label">Organizer Pasword</label>
                            <Controller
                                name='password'
                                control={joinControls}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <input type="password" value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                )}
                            />
                            {joinErrors.password && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.password.message}</span>}
                        </div>
                    </> : <>

                        <div className="mb-3">
                            <label className="form-label">Email address</label>
                            <Controller
                                name='email'
                                control={joinControls}
                                rules={{ required: true }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <input type="email" value={value} onChange={onChange} onBlur={onBlur} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                )}
                            />
                            {joinErrors.email && <span style={{ color: 'red', fontSize: '12px' }}>{joinErrors.email.message}</span>}
                        </div>
                    </>}
                    <div className="d-flex justify-content-around">
                        <button className="btn btn-success rounded-5 fs-5 mt-3" onClick={handleJoinMeeting(handleJoin)}>Request</button>
                    </div>
                </div>
            </>}
        </div>

    </div>
</div> */}